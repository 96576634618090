import { default as MUITab, TabProps as MUITabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

type CustomTabProps = {
  textAlign?: 'flex-start' | 'center' | 'flex-end';
  border?: boolean;
};

export type TabProps = CustomTabProps & MUITabProps;

const StyledTab = styled(MUITab, {
  shouldForwardProp: (prop) => prop !== 'textAlign' && prop !== 'border',
})<CustomTabProps>(
  ({
    theme: {
      palette: { primary, grey },
      spacing,
    },
    textAlign,
    border,
  }) => ({
    color: grey[700],
    borderBottom: border ? `2px solid ${grey[200]}` : 'none',
    minHeight: '40px',
    minWidth: '130px',
    padding: spacing(0, 1),
    '&.Mui-selected': {
      color: primary.main,
    },
    '&.MuiTab-iconWrapper': {
      alignItems: textAlign || 'flex-start',
    },
    '&.MuiTab-labelIcon': {
      alignItems: textAlign || 'flex-start',
    },
  }),
);

const Tab: FC<TabProps> = ({
  children,
  textAlign = 'flex-start',
  border = true,
  ...restTabProps
}): JSX.Element => {
  return (
    <StyledTab textAlign={textAlign} border={border} {...restTabProps}>
      {children}
    </StyledTab>
  );
};

export default Tab;
