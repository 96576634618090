import { GetServerSideProps } from 'next';

import { Make, User } from 'shared/types/models';

import { HomeContainer } from 'containers/home';
import Head from 'next/head';
import { GetGoogleReviewsResponse } from 'shared/types/api/getGoogleReviews';
import authorized from 'utils/authorized';
import { SEO_HOME_PAGE } from 'utils/seo';
import { AuctionsCountByMake } from '../shared/types/api/getAuctionsCountGroupedByMake';
import { FC } from 'react';

export type DefaultPageProps<T = Record<string, any>> = T & {
  user: User;
};

// User should be excluded from page container, if you need user - use userAtom
export type DefaultContainerProps<T = Record<string, any>> = Omit<T, 'user'>;

type HomePageProps = DefaultPageProps<{
  makes: Make[];
  auctionCountByMakes: AuctionsCountByMake[];
  reviews: GetGoogleReviewsResponse;
}>;

export type HomeContainerProps = HomePageProps;

const ConsumerHomePage: FC<HomePageProps> = ({
  makes,
  user,
  auctionCountByMakes,
  reviews,
}) => {
  return (
    <>
      <Head>
        <title>{SEO_HOME_PAGE.title()}</title>
        <meta name='description' content={SEO_HOME_PAGE.description()} />
        <meta name='keywords' content={SEO_HOME_PAGE.keywords()} />
      </Head>
      <HomeContainer
        reviews={reviews}
        user={user}
        makes={makes}
        auctionCountByMakes={auctionCountByMakes}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = authorized<HomePageProps>(
  async (ctx, rest, auth, user) => {
    try {
      const [makes, auctionCountByMakes, reviews] = await Promise.all([
        rest.getMakes(),
        rest.getAuctionsCountByMake(),
        rest.getSavedGoogleReviews(),
      ]);

      return {
        props: {
          makes,
          user,
          auctionCountByMakes,
          reviews,
        },
      };
    } catch (e) {
      return {
        props: {
          makes: [],
          user,
          auctionCountByMakes: [],
          reviews: [],
        },
      };
    }
  },
);

export default ConsumerHomePage;
