import {
  creditScoreInputAtom,
  milesInputAtom,
  moInputSelector,
} from 'containers/deal/recoil/atoms';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FiltersState } from 'shared/types/common';
import { DealTypeEnum, SortType } from 'shared/types/endpoints';
import {
  AuctionTypes,
  CarConditions,
  TierScore,
  TierScoreValueMap,
} from 'shared/types/models';
import { filtersAtom } from '../recoil';
import { useUpdateUrlSearchParameters } from './useUpdateUrlSearchParameters';
import {
  BEST_TERM_LENGTH,
  DEFAULT_MILES_PER_YEAR,
  SearchTerm,
} from 'shared/utils/constants';

type FilterValue =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | null
  | AuctionTypes
  | CarConditions
  | SortType;

type Props = {
  debounceTimeout?: number;
};

export type SetFilterValue = (
  filterName: keyof FiltersState,
  value: FilterValue,
) => void;

export type SetFiltersValue = (filters: FiltersState) => void;

// TODO SEARCH: sync setFilterValue & setFiltersValue logic
export const useFilters = ({ debounceTimeout = 500 }: Props = {}) => {
  const setMiles = useSetRecoilState(milesInputAtom);
  const setTerm = useSetRecoilState(moInputSelector);
  const setCreditScore = useSetRecoilState(creditScoreInputAtom);

  const [filters, setFilters] = useRecoilState(filtersAtom);

  const { updateUrlSearchParameters } = useUpdateUrlSearchParameters();

  const debouncedOnFilterChange = useMemo(
    () => debounce(updateUrlSearchParameters, debounceTimeout),
    [updateUrlSearchParameters, debounceTimeout],
  );

  const setFilterValue = useCallback<SetFilterValue>(
    (filterName, value) => {
      const newFilters = { ...filters, [filterName]: value };

      if (!value || (Array.isArray(value) && !value.length)) {
        delete newFilters[filterName];
      }

      if (filterName === 'term') {
        if (value === BEST_TERM_LENGTH) {
          delete newFilters.term;
        }

        setTerm(value as SearchTerm);
      } else if (filterName === 'miles') {
        if (value === DEFAULT_MILES_PER_YEAR) {
          delete newFilters.miles;
        }

        setMiles(value as number);
      } else if (filterName === 'creditScore') {
        if (value === TierScoreValueMap[TierScore.SuperElite]) {
          delete newFilters.creditScore;
        }

        setCreditScore(value as number);
      } else if (filterName === 'dealType' && value === DealTypeEnum.LEASE) {
        delete newFilters.dealType;
      }

      if (filterName !== 'offset') {
        delete newFilters.offset;
      }

      setFilters(newFilters);
      debouncedOnFilterChange?.({ filters: newFilters });
    },
    [
      setFilters,
      debouncedOnFilterChange,
      filters,
      setMiles,
      setTerm,
      setCreditScore,
    ],
  );

  const setFiltersValue = useCallback<SetFiltersValue>(
    (filtersValues) => {
      const newFilters = { ...filters, ...filtersValues };

      Object.keys(newFilters).forEach((key) => {
        if (!newFilters[key]) {
          delete newFilters[key];
        }
      });

      if (!Object.keys(newFilters).includes('offset')) {
        delete newFilters.offset;
      }

      setFilters(newFilters);

      debouncedOnFilterChange?.({ filters: newFilters });
    },
    [setFilters, debouncedOnFilterChange, filters],
  );

  const resetFilters = useCallback(() => {
    setFilters({});
  }, [setFilters]);

  const resetFiltersBy = useCallback(
    (filtersToReset: (keyof FiltersState)[]) => {
      const newFilters = omit(filters, filtersToReset);
      setFilters(newFilters);
      debouncedOnFilterChange?.({ filters: newFilters });
    },
    [setFilters, filters, debouncedOnFilterChange],
  );

  const filtersCount = useMemo(
    () =>
      Object.keys(filters).filter(
        (filter) =>
          ![
            // Basic filters
            'completed',
            'distance',
            // Basic lease conditions
            'miles',
            'term',
            // Pagination filters
            'perPage',
            'offset',
            // Count only max monthly payment filter
            'minMonthlyPayment',
          ].includes(filter),
      ).length,
    [filters],
  );

  return {
    setFilterValue,
    setFiltersValue,
    resetFilters,
    resetFiltersBy,
    filters,
    filtersCount,
  };
};
