import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { cleanObject } from '../utils';

export const useUpdateUrlSearchParameters = () => {
  const router = useRouter();

  const updateUrlSearchParameters = useCallback(
    ({ filters }) => {
      const query = cleanObject(filters, ['zipcode']);
      router.push({ pathname: '/deals', query }, undefined, { scroll: false });
    },
    [router],
  );

  return { updateUrlSearchParameters };
};
