import isPlainObject from 'lodash/isPlainObject';
import { ParsedUrlQuery } from 'querystring';
import { DEFAULT_SEARCH_DISTANCE } from 'shared/config';
import {
  DynamicAuctionFilters,
  DynamicFilterCategory,
} from 'shared/types/api/getAuctions';
import { FiltersState, NonEmptyArray } from 'shared/types/common';
import { DealTypeEnum, SortType } from 'shared/types/endpoints';
import { AuctionTypes, CarConditions } from 'shared/types/models';
import {
  AUCTIONS_PER_PAGE,
  DEFAULT_MILES_PER_YEAR,
  DEFAULT_TERM_LENGTH,
  SearchTerm,
} from 'shared/utils/constants';
import { SetFilterValue } from './hooks/useFilters';

export const formatCounted = (value: string, count: number): string => {
  return `${value}${count ? `  (${count})` : ''}`;
};

export const sortCountedAtoZ = (
  [valueA]: [string, string | number],
  [valueB]: [string, string | number],
) => {
  return valueA.localeCompare(valueB);
};

export const isEqualRange = (a: number[], b: number[]) => {
  return a[0] === b[0] && a[1] === b[1];
};

/**
 * The function is used to clean the object from empty values: null, undefined, empty string, empty array, empty object
 * @param obj - object to clean
 * @param omitKeys - keys to omit from cleaning
 * @returns - new cleaned object
 */
export const cleanObject = <T>(
  obj: T,
  omitKeys?: NonEmptyArray<keyof T>,
): Partial<T> => {
  return Object.entries(obj)
    .sort()
    .reduce((acc, [key, value]) => {
      if (omitKeys && omitKeys.includes(key as keyof T)) {
        acc[key] = value;

        return acc;
      }

      if ([undefined, null, ''].includes(value)) {
        return acc;
      }

      if (Array.isArray(value) && value.length === 0) {
        return acc;
      }

      if (isPlainObject(value)) {
        const cleaned = cleanObject(value);
        if (Object.keys(cleaned).length === 0) {
          return acc;
        } else {
          acc[key] = cleaned;
          return acc;
        }
      }

      acc[key] = value;

      return acc;
    }, {});
};

export const getStringParam = (val: string | string[]): string => {
  if (Array.isArray(val)) {
    return null;
  }
  return val || null;
};

function validString(val: unknown): boolean {
  return typeof val === 'string' && val.trim() !== '';
}

export const getArrayOfStringsParam = (
  val: string | string[],
): NonEmptyArray<string> => {
  if (!val) {
    return null;
  }

  if (!Array.isArray(val)) {
    if (validString(val)) {
      return [val];
    }
    return null;
  }

  if (!val.length) {
    return null;
  }

  const multipleStrings = val.filter(validString);

  if (!multipleStrings.length) {
    return null;
  }

  return multipleStrings as NonEmptyArray<string>;
};

export const getNumberParam = (val: string | string[]): number => {
  if (Array.isArray(val)) {
    return null;
  }
  const num = parseInt(val, 10);
  return isNaN(num) ? null : num;
};

export const getBooleanParam = (val: string | string[]): boolean => {
  if (Array.isArray(val)) {
    return null;
  }
  return val === 'true' ? true : val === 'false' ? false : null;
};

export const getNumberRangeParam = (val: string | string[]) => {
  if (!Array.isArray(val)) {
    return null;
  }

  if (!val || !val.length) {
    return null;
  }

  const range = val.map((v) => getNumberParam(v)).filter(Boolean);

  if (!range.length) {
    return null;
  }
  return range;
};

export const applyDefaultFilters = (filters: FiltersState): FiltersState => {
  const newFilters = { ...filters };

  newFilters.completed ||= false;
  newFilters.distance ||= DEFAULT_SEARCH_DISTANCE;
  newFilters.miles ||= DEFAULT_MILES_PER_YEAR;
  newFilters.perPage ||= AUCTIONS_PER_PAGE;
  newFilters.term ||= DEFAULT_TERM_LENGTH;

  return newFilters;
};

export const getFiltersFromUrl = (
  query: ParsedUrlQuery,
  skipDefaults?: boolean,
): FiltersState => {
  const filters: FiltersState = {};

  filters.body_style = getStringParam(query.body_style);
  filters.cityEco = getNumberRangeParam(query.cityEco);
  filters.completed = getBooleanParam(query.completed);
  filters.condition = getStringParam(query.condition) as CarConditions;
  filters.discount = getNumberParam(query.discount);
  filters.distance = getNumberParam(query.distance);
  filters.drivetrain = getStringParam(query.drivetrain);
  filters.exterior_color = getStringParam(query.exterior_color);
  filters.fuel_type = getStringParam(query.fuel_type);
  filters.hwyEco = getNumberRangeParam(query.hwyEco);
  filters.interior_color = getStringParam(query.interior_color);
  filters.make = getStringParam(query.make);
  filters.maxMonthlyPayment = getNumberParam(query.maxMonthlyPayment);
  filters.miles = getNumberParam(query.miles);
  filters.minMonthlyPayment = getNumberParam(query.minMonthlyPayment);
  filters.model = getStringParam(query.model);
  filters.msrp = getNumberRangeParam(query.msrp);
  filters.offset = getNumberParam(query.offset);
  filters.perPage = getNumberParam(query.perPage);
  filters.premium = getArrayOfStringsParam(query.premium);
  filters.feature = getArrayOfStringsParam(query.feature);
  filters.sortType = getStringParam(query.sortType) as SortType;
  filters.term = getStringParam(query.term) as SearchTerm;
  filters.transmission = getStringParam(query.transmission);
  filters.trim = getStringParam(query.trim);
  filters.type = getStringParam(query.type) as AuctionTypes;
  filters.wheel_type = getArrayOfStringsParam(query.wheel_type);
  filters.year = getNumberParam(query.year);
  filters.zipcode = getStringParam(query.zipcode);
  filters.creditScore = getNumberParam(query.creditScore);
  filters.is_featured = getBooleanParam(query.is_featured);
  filters.dealType = getStringParam(query.dealType) as DealTypeEnum;

  if (!skipDefaults) {
    return applyDefaultFilters(filters);
  }

  return filters;
};

export const isMsrpFilterValid = (dynamicFilters: DynamicAuctionFilters) =>
  dynamicFilters?.msrp?.every((v) => v > 0);

export const isCityFilterValid = (dynamicFilters: DynamicAuctionFilters) => {
  return (
    dynamicFilters?.cityEco?.length !== 0 &&
    dynamicFilters?.cityEco?.every((v) => v > 0)
  );
};

export const isHwyFilterValid = (dynamicFilters: DynamicAuctionFilters) => {
  return (
    dynamicFilters?.hwyEco?.length !== 0 &&
    dynamicFilters?.hwyEco?.every((v) => v > 0)
  );
};

export const normalizeOptionsFromQuery = (option: string | string[]) => {
  if (!option) {
    return new Set([]);
  }

  if (Array.isArray(option)) {
    return new Set(option);
  } else {
    return new Set([option as string]);
  }
};

export const makeOptionFilterChangeHandler = ({
  checked,
  key,
  name,
  filters,
  set,
}: {
  filters: FiltersState;
  key: DynamicFilterCategory;
  name: string;
  checked: boolean;
  set: SetFilterValue;
}) => {
  let options = filters[key];

  if (!options) {
    options = [];
  }

  if (typeof options === 'string') {
    options = [options];
  }

  if (!checked) {
    options = options.filter((optionName) => optionName !== name);
  } else {
    options = [...options, name];
  }

  set(key as keyof FiltersState, options);
};
